import { Button, Card, Container, Grid, TextField } from "@mui/material";
const LandingPage = () => {
  
  return (
    // <div className="new">
    //  <b>Welcome to HereSays Admin</b>
    // </div>
    <div className="new">
      <div className="newContainer">
        {/* <Navbar /> */}
      <Container flex={true}>
      <Grid item sm={12}>
      Welcome to HereSays Admin
      
        </Grid>
      </Container>
      </div>
    </div>
  );
};
export default LandingPage;
